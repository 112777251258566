<!-- category-header -->
<header class="category-header" *ngIf="headerStyle == 'category'">
  <div class="container h-100 overflow-visible px-0">
    <div class="row m-0 h-100">
      <div class="col-sm-12 m-0 px-0">
        <h1 class="h-100 text-left center">
          <div>
            <a [routerLink]="['/category']" class="title"> CitizeX Admin </a>
          </div>
          <div class="dropdown dropdown-img menu center navigation-menu">
            <button class="btn dropdown-toggle btn-img-circle fix-avtar-2-5" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
            </button>
            <div class="dropdown-menu dropdown-menu-right mr-0 shadow" aria-labelledby="dropdownMenuButton">
              <button type="button" class="close btn btn-link btn-tbl-link-danger" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" data-target=".navigation-menu">
                <i class="fi flaticon-cancel"></i>
              </button>
              <div class="text-center px-3 py-2" (click)="$event.stopPropagation()">
                <button [routerLink]="['/profile/view-profile/', defaultProfile.tagName]" (click)="$event.stopPropagation()"
                  class="btn btn-img-circle fix-avtar-4 my-1 mx-auto">
                  <img *ngIf="
                      defaultProfile.profileImage == '' ||
                      defaultProfile.profileImage == null
                    " src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                  <img *ngIf="
                      defaultProfile.profileImage != '' &&
                      defaultProfile.profileImage != null
                    " [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
                    alt="Profile Image" />
                </button>
                <h4 class="p-1">
                  {{ nonVerifiedUser.firstName }} {{ nonVerifiedUser.lastName }}
                </h4>
                <h6 class="p-1">&#64;{{ nonVerifiedUser.userName }}</h6>
              </div>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="['/category']"><i class="fi flaticon-list"></i> Category</a>
              <a class="dropdown-item" [routerLink]="['/category/addCategory']"><i class="fi flaticon-plus"></i> Add
                Category</a>
              <a class="dropdown-item" [routerLink]="['/deduplication']"><i class="fi flaticon-plus"></i>
                Deduplication</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="['/spam-reports']"><img
                  src="../../../assets/images/spam-report.svg" class="spam-img" />
                Spam Reports</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="['/verify-profiles']"><i class="fi flaticon-user"></i> User
                Profiles</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="['/claim-request']"><i class="fi flaticon-information"></i> Claim
                Request</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logOut()"><i class="fi flaticon-logout"></i> Logout</a>
              <!-- <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logOut()"><i class="fi flaticon-logout"></i> Logout</a> -->
            </div>
          </div>
        </h1>
      </div>
    </div>
  </div>
</header>
<!-- category-header Ends-->

<header class="{{ headerStyle }}-header" *ngIf="headerStyle == 'homepage'">
  <nav class="navbar navbar-expand-sm py-0 minh-100">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item active" *ngIf="this.loginBtn == false">
        <a class="nav-link" href="#" name="aboutUs" id="aboutUs" data-toggle="modal" (click)="aboutus()">
          <button class="btn btn-black py-1 px-sm-2 px-3 font-14">
            About Us
          </button></a>
      </li>
    </ul>
    <button *ngIf="this.logoutBtn === true" (click)="navigate()" class="navbar-toggler ml-auto btn btn-black mw-auto"
      data-toggle="modal" id="postReview" name="postReview" type="button">
      Login / Sign Up
    </button>
    <button *ngIf="this.logoutBtn !== true" class="navbar-toggler ml-auto" type="button" data-toggle="collapse"
      data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="fi flaticon-menu-square font-24"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="nav nav-pills nav-fill">
        <li class="nav-item active" *ngIf="this.loginBtn == true">
          <a class="nav-link" href="#" name="aboutUs" id="aboutUs" data-toggle="modal" (click)="aboutus()"> <button
              class="btn btn-black py-1 px-sm-2 px-3 font-14">About Us</button></a>
        </li>
      </ul>
      <ul class="nav nav-pills nav-fill ml-auto p-3 p-sm-0">
        <li title="Create a Conversation" class="nav-item active" *ngIf="verified">
          <a class="nav-link" name="postReview" id="postReview" data-toggle="modal" (click)="checkLogin()"><i
              class="fi flaticon-content font-24"></i> <span class="nav-text fw-600 font-16">Create a
              Conversation</span></a>
        </li>
        <li title="Verification status" class="nav-item" *ngIf="this.loginBtn == true">
          <a class="nav-link" [routerLink]="['/signup']"><i *ngIf="verified == false"
              class="fi flaticon-shield-failed font-danger font-24"></i><i *ngIf="verified == true"
              class="fi flaticon-shield-success font-success font-24"></i>
            <span class="nav-text font-16 fw-600">Verification status</span></a>
        </li>

        <li title="Create a page" class="nav-item"
          *ngIf="verified && this.loginBtn == true && user.reviewProfileId == null">
          <a class="nav-link" (click)="openCreatePageModal()"><i class="fi flaticon-user-add font-24"></i> <span
              class="nav-text font-16 fw-600">Create a page</span></a>
        </li>
        <li title="Your pages" class="nav-item" *ngIf="verified && this.loginBtn == true"
          [ngClass]="{ 'd-none': user.defaultProfileId == null }">
          <a class="nav-link" *ngIf="defaultProfile.profileName !== null"
            [routerLink]="['/profile/list-own-profiles']"><i class="fi flaticon-user-pencil font-24"></i> <span
              class="nav-text font-16 fw-600">Your pages</span></a>
        </li>

        <!-- homepage notification start -->
        <li title="Notifications" class="nav-item dropdown dropdown-img navigation-menu-notify notification-main"
          *ngIf="this.user !== null" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- <a class="nav-link" [routerLink]="['/search']"><i class="fi flaticon-notification font-24"></i></a> -->
          <a class="nav-link dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" (click)="getNotifications()">
            <i class="fi flaticon-notification font-24" *ngIf="!!notificationCount && notificationCount < 10"><span
                class="badge"> {{ notificationCount }} </span></i>
            <i class="fi flaticon-notification font-24" *ngIf="!!notificationCount && notificationCount >= 10"><span
                class="badge1"> 9 <span class="formore">+</span></span></i>
            <i class="fi flaticon-notification font-24" *ngIf="notificationCount === 0"></i>
            <span class="nav-text font-16 fw-600">Notifications</span>
          </a>

          <div class="dropdown-menu dropdown-menu-right mr-0 shadow notification-dropdown"
            aria-labelledby="dropdownMenuLink">
            <div class="Notify-header d-none">
              <p class="text-center h6 my-2">Notifications</p>
              <button type="button" class="close btn btn-link btn-tbl-link-danger" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" data-target=".notification-main">
                <i class="fi flaticon-cancel"></i>
              </button>
            </div>
            <div class="notification-inner-main main-height" (scroll)="onScroll($event)">
              <a class="dropdown-item notification-changes px-2 pb-2 pt-1" *ngFor="let notification of notificationList"
                (click)="$event.stopPropagation()" [ngClass]="{ unread: !notification.isRead }">
                <div class="col-12 row m-0 px-0 notification-mobile-custom">
                  <div class="col-md-auto col-2 pl-0 pr-1 pt-2">
                    <a class="nav-link btn dropdown-toggle btn-img-circle fix-avtar-3 mx-auto"
                      [routerLink]="['/profile/view-profile/', notification.byProfileTagName]"
                      (click)="$event.stopPropagation()" id="dropdownMenuButton" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <img *ngIf="notification.byProfileImage == '' || notification.byProfileImage == null"
                        src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                      <img *ngIf="notification.byProfileImage != '' && notification.byProfileImage != null"
                        [src]="notification.byProfileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
                        alt="Profile Image" />
                    </a>
                  </div>
                  <div class="col-md-10 px-0 pl-2" (click)="updateNotification(notification)"
                    (click)="$event.stopPropagation()">
                    <div class="lh-3-15 font-12">
                      <span class="h6 font-12 text-theme">{{ notification.byProfileName }} ({{
                        notification.byProfileTagName }})&nbsp;</span>
                      <span class="font-gray font-12">mentioned you:</span>
                      <!-- (@{{notification.byProfileTagName}}) page: </span> -->

                      <span class="text-theme font-12 my-1"
                        [innerHTML]="notification.content | shorten : 30 | applinkifyHtml : options"></span>
                      <span *ngIf="removeTags(notification.content).length > 30"> ... </span>
                    </div>
                    <div *ngIf="todayDate === notification.createdDate.substr(0, 10)" class="font-gray font-12">{{
                      notification.daysAgo }}</div>
                    <div *ngIf="todayDate != notification.createdDate.substr(0, 10)" class="font-gray font-12">{{
                      notification.createdDate | date : 'MMM d, y' }}</div>
                  </div>
                </div>
              </a>
              <a class="dropdown-item notification-changes px-2 py-3 last-notification" *ngIf="welcomeMessage === true">
                <div class="col-12 row m-0 px-0 notification-mobile-custom">
                  <div class="col-md-auto col-2 pl-0 pr-1 pt-sm-1">
                    <a class="nav-link btn dropdown-toggle btn-img-circle fix-avtar-3 mx-auto fixed-link"
                      id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <img src="../../../../assets/images/new-logo-text.png" alt="Profile Image" />
                    </a>
                  </div>
                  <div class="col-md-10 px-0 pl-2">
                    <div>
                      <p class="h6 font-14 text-theme">Welcome to CitizeX</p>
                      <p class="font-gray font-12">Hi {{ user.firstName }} ! Welcome to CitizeX. Complete your
                        verification in easy step.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </li>
        <!-- homepage notification end -->

        <li class="nav-item mx-0">
          <button *ngIf="this.logoutBtn == true" routerLink="/login" class="btn btn-black" data-toggle="modal"
            id="postReview" name="postReview" type="button">Login / Sign Up</button>
        </li>
        <li *ngIf="this.loginBtn == true" class="nav-item dropdown dropdown-img navigation-menu">
          <a class="nav-link btn dropdown-toggle btn-img-circle fix-avtar-2-5 mx-auto" href="#" id="dropdownMenuButton"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null"
              src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
            <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null"
              [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
              alt="Profile Image" />
          </a>
          <div class="dropdown-menu dropdown-menu-right mr-0 shadow" aria-labelledby="dropdownMenuButton">
            <button type="button" class="close btn btn-link btn-tbl-link-danger" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-target=".navigation-menu">
              <i class="fi flaticon-cancel"></i>
            </button>
            <div class="text-center px-3 py-2" (click)="$event.stopPropagation()">
              <button *ngIf="defaultProfile.profileName != null"
                [routerLink]="['/profile/view-profile/', defaultProfile.tagName]" (click)="$event.stopPropagation()"
                class="btn btn-img-circle fix-avtar-4 my-1 mx-auto">
                <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null"
                  src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null"
                  [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
                  alt="Profile Image" />
              </button>
              <button *ngIf="defaultProfile.profileName == null" (click)="$event.stopPropagation()"
                class="btn btn-img-circle fix-avtar-4 my-1 mx-auto">
                <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null"
                  src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null"
                  [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
                  alt="Profile Image" />
              </button>
              <h4 class="p-1" *ngIf="!verified">{{ nonVerifiedUser.firstName }} {{ nonVerifiedUser.lastName }}</h4>
              <h6 class="p-1" *ngIf="!verified">&#64;{{ nonVerifiedUser.userName }}</h6>
              <h4 class="p-1" *ngIf="verified && defaultProfile.profileName == null">{{ nonVerifiedUser.firstName }} {{
                nonVerifiedUser.lastName }}</h4>
              <h6 class="p-1" *ngIf="verified && defaultProfile.profileName == null">&#64;{{ nonVerifiedUser.userName }}
              </h6>
              <h4 class="p-1" *ngIf="verified && defaultProfile.profileName !== null">{{ defaultProfile.profileName }}
              </h4>
              <h6 class="p-1" *ngIf="verified && defaultProfile.profileName !== null">&#64;{{ defaultProfile.tagName }}</h6>

              <a *ngIf="defaultProfile.id != null" class="btn btn-underline font-16"
                [routerLink]="['/profile/edit-profile/', defaultProfile.id]"><i class="fi flaticon-edit"></i> Edit</a>
            </div>
            <div class="dropdown-divider"></div>
            <!-- <a class="btn dropdown-item pointer" [routerLink]="['/profile/edit-profile/', defaultProfile.id]"><i class="fi flaticon-logout"></i> Edit</a> -->
            <a class="btn dropdown-item pointer" [routerLink]="['/profile/block-profile/']"><i
                class="fi flaticon-user-remove"></i> Blocked Profiles</a>
            <div class="dropdown-divider"></div>
            
            <div class="have-feedback d-flex align-items-center" (click)="openFeedbackModal()" >
              <img src="../../../../assets/images/haveFeedbackIcon.png" alt="have feedback" class="mr-1" width="20" height="20">
              <a class="btn dropdown-item pointer ml-sm-2 ml-4"> Have feedback?</a>
            </div>
            <div class="dropdown-divider"></div>

            <div class="extra" (click)="$event.stopPropagation()"></div>
            <a class="btn dropdown-item pointer" (click)="logOut()"><i class="fi flaticon-logout"></i> Logout</a>
            <div class="extra" (click)="$event.stopPropagation()"></div>

            <div class="dropdown-divider"></div>
            <!-- <a class="btn dropdown-item pointer" [routerLink]="['/profile/edit-profile/', defaultProfile.id]"><i class="fi flaticon-logout"></i> Edit</a> -->
            <a class="btn dropdown-item pointer" [routerLink]="['/deleteAccount']"><i class="fi flaticon-delete"></i>
              Delete Account</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <a class="extra-header" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
    aria-expanded="false" aria-label="Toggle navigation"></a>
</header>

<app-post-review-model></app-post-review-model>

<header class="{{ headerStyle }}-header" *ngIf="headerStyle == 'profile' || headerStyle == 'firstLogin'">
  <nav class="navbar navbar-expand-md py-0 minh-100">
    <div class="col-auto">
      <a [routerLink]="['/homepage']" class="navbar-brand btn btn-img-no-effect h-40px d-block border-0">
        <img src="../../../../assets/images/logo-text.png" class="" alt="Logo Image" />
      </a>
    </div>
    <div class="col p-0">
      <form (submit)="headerSearchProfiles()" class="col-12 col-sm-8 col-lg-4 pl-0 ml-auto header-search"
        *ngIf="headerStyle == 'profile'">
        <button type="button" class="btn btn-no-change search-sm bold" (click)="searchOpen()">
          <i class="fi flaticon-search"></i>
        </button>
        <div class="search-field">
          <div class="d-flex homepage-search header-search-custom">
            <!-- <input type="text" name="search" id="search" placeholder="Search profiles to review..." [(ngModel)]="searchValue" #searchString class="form-control search-input">
            -->
            <div class="ng-autocomplete w-100">
              <ng-autocomplete [data]="searchSuggetionList" name="searchValue" [initialValue]="searchValue"
                class="w-100" [searchKeyword]="'tagName'" (selected)="searchSelect($event)"
                (inputChanged)="onChangeSearch($event)" [itemTemplate]="itemTemplate" [minQueryLength]="1"
                (focus)="onFocus($event)" placeholder="Search an Individual..." (keydown.enter)="onEnter($event)">
              </ng-autocomplete>
              <ng-template #itemTemplate let-item class="dropdown-menu search-dropdown">
                <a [innerHTML]="item.displayName"></a>
              </ng-template>
            </div>
            <!-- <button type="submit" [disabled]="!searchValue" class="search-btn font-weight-bold">
              <i class="fi flaticon-search"></i>
            </button> -->
          </div>
          <div class="extra" (click)="searchClose()"></div>
        </div>
      </form>
    </div>
    <button *ngIf="this.logoutBtn !== true" class="navbar-toggler nav-link" type="button" data-toggle="collapse"
      data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="fi flaticon-menu-square font-24"></i>
    </button>
    <button *ngIf="this.logoutBtn === true" routerLink="/login" class="btn btn-black mw-auto" data-toggle="modal"
      id="postReview" name="postReview" type="button">
      Login / Sign Up
    </button>
    <div class="col-12 col-md-auto px-0">
      <div class="collapse navbar-collapse w-auto" id="navbarNavDropdown">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item active" *ngIf="this.loginBtn == true">
            <a class="nav-link" href="#" name="aboutUs" id="aboutUs" data-toggle="modal" (click)="aboutus()"> <button
                class="btn btn-black py-1 px-sm-2 px-3 font-14">About Us</button></a>
          </li>
        </ul>
        <ul class="nav nav-pills nav-fill p-3 p-sm-0">
          <li title="Create a Conversation" class="nav-item active" *ngIf="verified">
            <a class="nav-link" name="postReview" id="postReview" data-toggle="modal" (click)="checkLogin()"><i
                class="fi flaticon-content font-24"></i> <span class="nav-text font-16 fw-600">Create a
                Conversation</span></a>
          </li>
          <li title="Verification status" class="nav-item" *ngIf="this.loginBtn == true">
            <a class="nav-link" [routerLink]="['/signup']"><i *ngIf="verified == false"
                class="fi flaticon-shield-failed font-danger font-24"></i><i *ngIf="verified == true"
                class="fi flaticon-shield-success font-success font-24"></i>
              <span class="nav-text font-16 fw-600">Verification status</span></a>
          </li>
          <li title="Create a page" class="nav-item"
            *ngIf="verified && this.loginBtn == true && user.reviewProfileId == null">
            <a class="nav-link" (click)="openCreatePageModal()"><i class="fi flaticon-user-add font-24"></i> <span
                class="nav-text font-16 fw-600">Create a page</span></a>
          </li>
          <li title="Your pages" class="nav-item" *ngIf="verified && this.loginBtn == true"
            [ngClass]="{ 'd-none': user.defaultProfileId == null }">
            <a class="nav-link" *ngIf="defaultProfile.profileName !== null"
              [routerLink]="['/profile/list-own-profiles']"><i class="fi flaticon-user-pencil font-24"></i> <span
                class="nav-text font-16 fw-600">Your pages</span></a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/profile/list-own-profiles']"><i class="fi flaticon-notification font-24"></i></a>
          </li> -->

          <!-- profile notification start -->
          <li title="Notifications" class="nav-item dropdown dropdown-img navigation-menu-notify notification-main"
            *ngIf="this.user !== null">
            <!-- <a class="nav-link" [routerLink]="['/search']"><i class="fi flaticon-notification font-24"></i></a> -->
            <a class="nav-link dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false"><i class="fi flaticon-notification font-24"
                (click)="getNotifications()" *ngIf="!!notificationCount && notificationCount < 10"><span class="badge">
                  {{ notificationCount }} </span></i>
              <i class="fi flaticon-notification font-24" *ngIf="!!notificationCount && notificationCount >= 10"
                (click)="getNotifications()"><span class="badge1"> 9 <span class="formore">+</span></span></i>
              <i class="fi flaticon-notification font-24" *ngIf="notificationCount === 0"
                (click)="getNotifications()"></i>
              <span class="nav-text font-16 fw-600">Notifications</span>
            </a>

            <div class="dropdown-menu dropdown-menu-right mr-0 shadow notification-dropdown"
              aria-labelledby="dropdownMenuLink">
              <div class="Notify-header d-none">
                <p class="text-center h6 my-2">Notifications</p>
                <button type="button" class="close btn btn-link btn-tbl-link-danger" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" data-target=".notification-main">
                  <i class="fi flaticon-cancel"></i>
                </button>
              </div>
              <div class="notification-inner-main main-height" (scroll)="onScroll($event)">
                <a class="dropdown-item notification-changes px-2 pb-2 pt-1"
                  *ngFor="let notification of notificationList" [ngClass]="{ unread: !notification.isRead }">
                  <div class="col-12 row m-0 px-0 notification-mobile-custom">
                    <div class="col-md-auto col-2 pl-0 pr-1 pt-2">
                      <a class="nav-link btn dropdown-toggle btn-img-circle fix-avtar-3 mx-auto"
                        [routerLink]="['/profile/view-profile/', notification.byProfileTagName]"
                        (click)="$event.stopPropagation()" id="dropdownMenuButton" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <img *ngIf="notification.byProfileImage == '' || notification.byProfileImage == null"
                          src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                        <img *ngIf="notification.byProfileImage != '' && notification.byProfileImage != null"
                          [src]="notification.byProfileImage"
                          onerror="this.src='../../../assets/images/noprofile2.png';" alt="Profile Image" />
                      </a>
                    </div>
                    <div class="col-md-10 px-0 pl-2" (click)="updateNotification(notification)">
                      <div class="lh-3-15 font-12">
                        <span class="h6 font-12 text-theme">{{ notification.byProfileName }} ({{
                          notification.byProfileTagName }})&nbsp;</span>
                        <span class="font-gray font-12"> mentioned you: </span>
                        <!-- (@{{notification.byProfileTagName}}) page: </span> -->
                        <!-- <span class="font-primary-color fw-600 font-12">@{{notification.onProfileTagName}} </span> -->
                        <span class="text-theme font-12 my-1"
                          [innerHTML]="notification.content | shorten : 30 | applinkifyHtml : options"></span>
                        <span *ngIf="removeTags(notification.content).length > 30"> ... </span>
                      </div>
                      <div *ngIf="todayDate === notification.createdDate.substr(0, 10)" class="font-gray font-12">{{
                        notification.daysAgo }}</div>
                      <div *ngIf="todayDate != notification.createdDate.substr(0, 10)" class="font-gray font-12">{{
                        notification.createdDate | date : 'MMM d, y' }}</div>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item notification-changes px-2 py-3 last-notification"
                  *ngIf="welcomeMessage === true">
                  <div class="col-12 row m-0 px-0 notification-mobile-custom">
                    <div class="col-md-auto col-2 pl-0 pr-1 pt-sm-1">
                      <!-- <a [routerLink]="['/profile/view-profile/', review.byProfileTagName]" (click)="$event.stopPropagation()" class="btn btn-img-circle fix-avtar-3 mx-auto">
                    <img *ngIf="!review.byProfileImage" src="../../../assets/images/noprofile2.png" alt="Profile Image" />
                    <img *ngIf="!!review.byProfileImage" [src]="review.byProfileImage" alt="Profile Image" />
                  </a> -->
                      <a class="nav-link btn dropdown-toggle btn-img-circle fix-avtar-3 mx-auto fixed-link"
                        id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <img src="../../../../assets/images/new-logo-text.png" alt="Profile Image" />
                      </a>
                    </div>
                    <div class="col-md-10 px-0 pl-2">
                      <div>
                        <p class="h6 font-14 text-theme">Welcome to CitizeX</p>
                        <p class="font-gray font-12">Hi {{ user.firstName }}! Welcome to CitizeX. Complete your
                          verification with easy steps.</p>
                        <!-- <span *ngIf="notification.content.length > 225">
                      ...
                    </span> -->
                      </div>
                      <!-- <div class="font-gray font-12">{{notification.daysAgo}}</div> -->
                    </div>
                  </div>
                </a>
                <!-- <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a> -->
                <!-- <div class="dropdown-item loader-custom" [style.display]="loading">
              <div [style.display]="loading" id="imagloader" class="view-profileLoader1"></div>
            </div> -->
              </div>
            </div>
            <!-- notification end -->
          </li>
          <!-- profile notification end -->

          <li *ngIf="this.loginBtn == true" class="nav-item dropdown dropdown-img navigation-menu">
            <a class="nav-link btn dropdown-toggle btn-img-circle fix-avtar-2-5 mx-auto" href="#"
              id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null"
                src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
              <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null"
                [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
                alt="Profile Image" />
            </a>
            <div class="dropdown-menu dropdown-menu-right mr-0 shadow" aria-labelledby="dropdownMenuButton">
              <button type="button" class="close btn btn-link btn-tbl-link-danger" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" data-target=".navigation-menu">
                <i class="fi flaticon-cancel"></i>
              </button>
              <div class="text-center px-3 py-2">
                <button *ngIf="defaultProfile.profileName != null"
                  [routerLink]="['/profile/view-profile/', defaultProfile.tagName]"
                  class="btn btn-img-circle fix-avtar-4 my-1 mx-auto" (click)="closeNavbarCollepse()">
                  <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null"
                    src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                  <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null"
                  [src]="defaultProfile.profileImage + '?' + timestamp" onerror="this.src='../../../assets/images/noprofile2.png';"
                    alt="Profile Image" />
                </button>
                <button *ngIf="defaultProfile.profileName == null" class="btn btn-img-circle fix-avtar-4 my-1 mx-auto"
                  (click)="closeNavbarCollepse()">
                  <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null"
                    src="../../../../assets/images/noprofile2.png" alt="Profile Image" />
                  <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null"
                    [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';"
                    alt="Profile Image" />
                </button>
                <h4 class="p-1" *ngIf="!verified">{{ nonVerifiedUser.firstName }} {{ nonVerifiedUser.lastName }}</h4>
                <h6 class="p-1" *ngIf="!verified">&#64;{{ nonVerifiedUser.userName }}</h6>
                <h4 class="p-1" *ngIf="verified && defaultProfile.profileName == null">{{ nonVerifiedUser.firstName }}
                  {{ nonVerifiedUser.lastName }}</h4>
                <h6 class="p-1" *ngIf="verified && defaultProfile.profileName == null">&#64;{{ nonVerifiedUser.userName }}
                </h6>
                <h4 class="p-1" *ngIf="verified && defaultProfile.profileName !== null">{{ defaultProfile.profileName }}
                </h4>
                <h6 class="p-1" *ngIf="verified && defaultProfile.profileName !== null">&#64;{{ defaultProfile.tagName }}
                </h6>
                <a *ngIf="defaultProfile.id != null" class="btn btn-underline font-16"
                  [routerLink]="['/profile/edit-profile/', defaultProfile.id]"><i class="fi flaticon-edit"></i> Edit</a>
              </div>
              <div class="dropdown-divider"></div>
              <!-- <a class="btn dropdown-item pointer" [routerLink]="['/profile/edit-profile/', defaultProfile.id]"><i class="fi flaticon-logout"></i> Edit</a> -->
              <a class="btn dropdown-item pointer" [routerLink]="['/profile/block-profile/']"><i
                  class="fi flaticon-user-remove"></i> Blocked Profiles</a>
              <div class="extra"></div>

              <div class="dropdown-divider"></div>
              <div class="have-feedback d-flex align-items-center" (click)="openFeedbackModal()" >
                <img src="../../../../assets/images/haveFeedbackIcon.png" alt="have feedback" class="mr-1" width="20" height="20">
                <a class="btn dropdown-item pointer ml-sm-2 ml-4"> Have feedback?</a>
              </div>

              <div class="dropdown-divider"></div>
              <a class="btn dropdown-item pointer" (click)="logOut()"><i class="fi flaticon-logout"></i> Logout</a>
              <div class="extra"></div>

              <div class="dropdown-divider"></div>
              <!-- <a class="btn dropdown-item pointer" [routerLink]="['/profile/edit-profile/', defaultProfile.id]"><i class="fi flaticon-logout"></i> Edit</a> -->
              <a class="btn dropdown-item pointer" [routerLink]="['/deleteAccount']"><i class="fi flaticon-delete"></i>
                Delete Account</a>
              <div class="extra"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <a class="extra-header" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
    aria-expanded="false" aria-label="Toggle navigation"></a>
</header>

<header class="profile-header" *ngIf="headerStyle == 'privacyAndPolicy'">
  <nav class="navbar navbar-expand-md py-0 minh-100">
    <div class="col-auto">
      <a [routerLink]="['/homepage']" class="navbar-brand btn btn-img-no-effect h-40px d-block border-0">
        <img src="../../../../assets/images/logo-text.png" class="" alt="Logo Image" />
      </a>
    </div>
  </nav>
</header>

<!-- Restriction Modal -->
<div class="modal fade" id="restrictModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="text-center theme-color">
          <i class="fi flaticon-information font-40"></i>
        </h1>
        <h6 class="text-center bold my-3 theme-color">Sorry !</h6>
        <p class="text-center theme-color">
          You must have 2 user verifications to be able to post a review on
          CitizeX.
        </p>
        <div class="col-12 text-center">
          <button [routerLink]="['/signup']" class="btn btn-outline-black mt-4 mb-2 mx-2 py-1 font-12"
            data-dismiss="modal">
            Get Verified
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #restrictModal -->
<!-- Restriction Modal Ends -->

<!-- Restriction Modal -->
<div class="modal fade" id="restrictProfileModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="text-center theme-color">
          <i class="fi flaticon-information font-40"></i>
        </h1>
        <h6 class="text-center bold my-3 theme-color">Sorry !</h6>
        <p class="text-center theme-color">
          You must have your own profile on CitizeX to post a review.
        </p>
        <div class="col-12 text-center">
          <button [routerLink]="['/profile/search-profile']" class="btn btn-outline-black mt-4 mb-2 mx-2 py-1 font-12"
            data-dismiss="modal">
            Create your page
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #restrictModal -->
<!-- Restriction Modal Ends -->

<div class="modal fade" id="createPageModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-2 mb-2">Create a Page</h5>
        <button type="button" class="close mt-2 font-32" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-justify theme-color mb-2">
          As a verified user, you must create a page with your information
          before you can start posting reviews. Please make sure that your first
          name, last name and date of birth matches your government issued ID
          used for verification.
        </p>
        <p class="text-justify theme-color mb-2">
          Once you create your own page, you will be able to create pages for
          other individuals. However, any page created for any individual will
          remain "unclaimed" until claimed by the individual.
        </p>
        <p class="text-justify theme-color mb-2">
          *Please refrain from making fake or duplicate pages else your account
          will be flagged!
        </p>
        <div class="col-12 text-center">
          <button class="btn btn-black mt-4 mb-2 mx-2 py-2 px-sm-2 px-3 font-14"
            (click)="redirectToCreateProfilePage()">
            Continue
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>

<div class="modal fade" id="deleteFieldModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title mt-2 mb-2">Delete Account</h3>
        <button type="button" class="close mt-2 font-32" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-justify theme-color mb-2 delete_account">
          Are you sure you want to delete this account?
        </p>
        <div class="col-12 text-center">
          <button type="reset" class="btn btn-outline-black mt-4 mb-2 mx-2 font-16 mw-auto" data-dismiss="modal">
            No
          </button>
          <button class="btn btn-black mt-4 mb-2 mx-2 font-16 mw-auto" (click)="deleteAccount()">
            Yes
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!------------------------About-Us-Modal------------->
<div class="modal fade" id="aboutUsModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title mt-2 mb-2">About Us</h3>
        <button type="button" class="close mt-2 font-32" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-justify theme-color mb-2 fw-700">
          Please be advised that the CitizeX platform is not affiliated with any
          government entity. The candidate and election information provided on
          our platform is sourced directly from the Google Civic API and the
          Ballotpedia API.
        </p>
        <!-- <p class="text-justify theme-color mb-2">
              CitizeX is a non-autonomous platform dedicated to enhancing democracy by bringing authenticity back to political discourse. It provides voters with straightforward access to information about candidates and elected officials. Its user-friendly interface makes it easy to find elected representatives and candidates for upcoming elections.
            </p> -->
        <p class="text-justify theme-color mb-2">
          CitizeX is a non-autonomous platform dedicated to enhancing democracy
          by bringing authenticity back to political discourse. It fosters
          authentic dialogue between verified users, candidates, and elected
          officials. With a streamlined user verification process, CitizeX
          ensures that users are who they say they are, creating a more credible
          and constructive space for discourse of local issues, legislation, and
          campaigns.
        </p>
        <!-- <p class="text-justify theme-color mb-2">
              CitizeX fosters dialogue through reviews and comments, enabling users to discuss local issues, upcoming legislation, and campaign news. With a streamlined user verification process, CitizeX ensures post authenticity, creating a more credible and constructive space for political discourse.
            </p> -->
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>

<!-- Restriction Modal -->
<div class="modal fade" id="restrictUnclaimedProfileModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="text-center theme-color">
          <i class="fi flaticon-information font-40"></i>
        </h1>
        <h6 class="text-center bold my-3 theme-color">Sorry !</h6>
        <p class="text-center theme-color">
          Your claim request is currently under review. You will be notified
          within 24 to 48 hours. Until your request is approved, you are
          temporarily restricted from posting.
        </p>
        <div class="col-12 text-center">
          <button type="button" class="btn btn-outline-black mt-4 mb-2 mx-2 py-1 font-12" data-dismiss="modal">
            ok
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #restrictModal -->

<!-- have feedback modal  -->

<div class="modal fade havefeedbackmodal" tabindex="-1" role="dialog" id="haveFeedback">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-20 fw-600">Have Feedback?</h5>
        <button type="button" class="close" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group d-flex aling-items-center radio-btn-group">
          <label class="d-flex align-items-center font-18 fw-600 mb-0">Report a bug <input type="radio" name="feedback" value="bug" class="ml-3"></label>
          <label class="d-flex align-items-center font-18 fw-600 mb-0">Feedback <input type="radio" name="feedback" value="feedback" class="ml-3"></label> 
        </div>
        <div class="form-group">
          <label for="description" class="font-14 fw-600">Description</label>
          <textarea id="description" class="form-control" rows="5"></textarea>
        </div>
      </div>
      <div class="d-flex justify-content-end mr-4">
        <button type="button" class="btn btn-outline-black mt-4 pt-2 mb-3 mx-2 font-16 fw-600" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-black mt-4 pt-2 mb-3 mx-2 font-16 fw-600">Report</button>
      </div>
    </div>
  </div>
</div>

<!-- have feedback modal  -->